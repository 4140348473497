<template>
    <v-container fluid class="mb-12">
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid class="p-3">
                        <v-row class="mb-1">
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-filter</v-icon> Filter: </h6>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-slot-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="machineType"
                                    :items="machineTypes"
                                    item-value="mach_type"
                                    item-text="descr"
                                    label="Machine Type"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => fetchMachineID(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-state-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="mach_id"
                                    :items="machIds"
                                    item-value="mach_id"
                                    item-text="descr"
                                    label="Machine Id"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="start_period"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="start_period"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="end_period"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_period"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block  style="padding: 21px;"
                                elevation="2" small @click="clear()">Clear</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block style="padding: 21px;"
                                color="info" elevation="2" small @click="fetchData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumDefectAnalysis" outlined height="450">
                    <v-card-text>
                        <div id="container1" style="height: 400px; width: 100%;"></div>
                        <div id="container2" class="text-center">
                            <span v-if="labels.length > 0" class="font-weight-bold mt-10 text-uppercase">
                                Major Defect for Machine Type {{ machineType_field }} from {{ start_period_field }} to {{ end_period_field }}:
                                <template v-for="(label, index) in labels">
                                    <span v-if="index === labels.length - 1">
                                        "{{ label }}"
                                    </span>
                                    <span v-else>
                                        "{{ label }}" and
                                    </span>
                                </template>
                            </span>
                            <span v-else class="font-weight-bold mt-10 text-uppercase">
                                Tidak ada data yang tersedia.
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data(){
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Production',
                disabled: true,
                href: 'breadcrumbs_link_1',
                },
                {
                text: 'Defect Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machineType: '',
            machineTypes: [],
            mach_id: '',
            machIds: [],
            defectAnalysis: [],
            commodity: '',
            commodities: [],
            start_period: '',
            end_period: '',
            brand: '',
            brands: [],
            thick: '',
            thickness: [],
            width: '',
            labels: [],
            widths: [],
            quality: '',
            qualities: [],
            date_from: '',
            modal: false,
            modal_to: false,
            date_to: '',
            showData: true,
            x: window.matchMedia("(max-width: 991px)"),
            searchItem: '',
            header:[
                { text: 'Machine Type', value: 'machine_type' , align:'start'},
                { text: 'Machine ID', value: 'machine_id' , align:'left'},
                { text: 'Commodity', value: 'commodity_descr' , align:'left'},
                { text: 'Brand', value: 'brand' , align:'left'},
                { text: 'Quality', value: 'quality_id' , align:'left', width:50},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Weight (TON)', value: 'wgt' , align:'right'},
            ],
            headersColumn:{
                'Period': 'period', 
                'Thick Name': 'thick_name',
                'Machine Type' : 'machine_type',
                'Machine ID' : 'machine_id',
                'Commodity' : 'commodity_descr',
                'Brand' : 'brand',
                'Quality' : 'quality_id',
                'Thick': 'thick',
                'Width': 'width',
                'Weight (KG)' : 'wgt'
            },
            detailData:[],
            detail: null,
            dialog: false,
            showDataDetail: true
        }  
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        console.log("me")
        await this.fetchMachineType()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.start_period = ''
            this.end_period = ''
            this.machineType = ''
            this.mach_id = ''
        },
        async fetchMachineType(){
            var reqBody = {
                'mach_type': this.machineType ? this.machineType : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/machine_type', reqBody, false, false, false)
            if (respData.status === 200) {
                this.machineTypes = respData.data
            }
        },
        async fetchMachineID(event){
            if (event == '' || event == null) {
                this.mach_id = ''
            } else {
                var reqBody = {
                    'mach_type': this.machineType ? this.machineType : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/machine_id', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.machIds = respData.data
                }
            }
        },
        async fetchData() {
            this.$store.dispatch('setOverlay', true);

            if (this.machineType == '' || this.start_period == '' || this.end_period == '') {
                this.$store.dispatch('setOverlay', false);

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill Machine Type, Machine ID and Start Period",
                    visible: true
                };
                return false;
            }

            var start_period = this.start_period ? new Date(this.start_period).toISOString().substr(0, 7).replace(/-/g, "") : '';
            var end_period = this.end_period ? new Date(this.end_period).toISOString().substr(0, 7).replace(/-/g, "") : '';

            var reqBody = {
                'mach_type': this.machineType ? this.machineType : '',
                'mach_id': this.mach_id ? this.mach_id : '',
                'start_period': start_period,
                'end_period': end_period
            };

            try {
                await axios.post(`${process.env.VUE_APP_URL}/api/v3/sm/production/defect_trans`, reqBody, {
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    }
                }).then(res => {
                    this.defectAnalysis = res.data.data
                    console.log(this.defectAnalysis)

                    var dt = [];

                    for (var i = 0; i < res.data.data.length; i++) {
                        dt.push({ label: res.data.data[i].label , y: parseFloat(res.data.data[i].y / 1000)});
                    }
                    var container = 'container1';
                    var title = this.machineType+" Summary";
                    var label = "CGL"+this.mach_id+" Summary";

                    this.topTwo(start_period, end_period, this.machineType);
                    // this.$store.dispatch('setOverlay', false);
                    this.getChart(dt, container, title, label)
                });

            } catch (error) {
                console.error('Error fetching data:', error);
                this.$store.dispatch('setOverlay', false);
            }
        },
        async topTwo(start_period, end_period, machineType) {
            this.$store.dispatch('setOverlay', true);

            if (this.machineType == '' || this.start_period == '' || this.end_period == '') {
                this.$store.dispatch('setOverlay', false);

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill Machine Type, Machine ID and Start Period",
                    visible: true
                };
                return false;
            }
            
            var reqBody = {
                'mach_type': machineType ? machineType : '',
                'mach_id': this.mach_id ? this.mach_id : '',
                'start_period': start_period,
                'end_period': end_period
            };

            try {
                const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sm/production/defect_trans/top-two`, reqBody, {
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    }
                });

                this.defectAnalysis = res.data.data;
                console.log(this.defectAnalysis);

                var dt = [];

                for (var i = 0; i < res.data.data.length; i++) {
                    dt.push(res.data.data[i].label);
                }
                this.labels = dt;

                this.start_period_field = start_period; 
                this.end_period_field = end_period; 
                this.machineType_field = machineType; 

                this.$store.dispatch('setOverlay', false);
            } catch (error) {
                console.error('Error fetching data:', error);
                this.$store.dispatch('setOverlay', false);
            }
        },
        getChart(dt, container, title, label){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    type: "column",
                    // click: this.onClick,
                    name: "Defect",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    // indexLabelFontColor: "#FFF",
                    showInLegend: true,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    color: "#75cfb8",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt;
            // this.showDefaultText(chart, "No Data Found!");
            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        }, 
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        }, 
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        }, 
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
            e.chart.render();
        }, 
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
    },
    watch: {
    }
}
</script>